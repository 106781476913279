<script>
import ReturnPointsForm from './ReturnPointsForm'

export default {
  extends: ReturnPointsForm,

  computed: {
    blockText() {
      return ''
    },

    warningButtonText() {
      return ''
    }
  },

  methods: {
    onSubmit() {
      const formData = this.beforeSubmit()

      this.createReturnPoint(formData)
        .then(() => {
          this.successSubmit({ message: 'Точка возврата успешно создана', routeName: 'return-points' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>